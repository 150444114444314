/* You can add global styles to this file, and also import other style files */
.footer{
    margin-top: 10px;
    background-color: rgba(22, 21, 21, 0.849);
    justify-content: center;
    flex-wrap: wrap;
    color: rgba(240, 255, 240, 0.562);
    font-size: 13px;
    bottom: 0;
    width: 100%;
    position: relative;
    padding: 7.5%;

}

.header{
    display: contents;
    
}
.logo{
    max-width: 100px;
}
.telefono{
}
.li a{
   text-decoration: none;  
   color: honeydew;

}
.ul{
    padding-top: 15px;
}
html, body { 
    height: 100%;
    margin-bottom: 40px;
    margin: 0;
    position: relative;
    padding-bottom: 70px;
}


.imgs{
    display: flex;
    align-items: center;
}




.content {
    background-color: #ffffff;
    margin: 1em;
    min-height: 12.6vh;
    padding: 1em;
}